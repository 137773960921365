import React from 'react';
import './Login.css';  // Login-specific styles
import LoginButton from './LoginButton';  // Import the login button


function Login() {
  return (
    <div className="login-container">
      {/* White topbar with logo and title */}
      <div className="login-navbar">
        <div className="login-logo-container">
          <img src="/primarylogo.png" alt="Logo" />
          <span>Ona On-Demand</span>
        </div>
      </div>

      {/* Main content with video background */}
      <div className="main-content">
        {/* Video background */}
        <div className="video-background">
          <video autoPlay loop muted className="video-hero">
            <source src="https://asoba.co/wp-content/uploads/2024/09/hero.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        {/* Dark overlay */}
        <div className="video-overlay"></div>

        {/* Hero Content */}
        <div className="hero-content">
          <h1>Energy Asset Intelligence & Market Insights</h1><h1> On-Demand.</h1>
          <p>Coming September 2024</p>
          <div className="hero-buttons">
            <LoginButton /> 
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
