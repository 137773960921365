import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';


function FormComponentInterpolate() {

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const onDrop = useCallback((acceptedFiles) => {
    // Handle the file upload logic here
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="form-container">

      <form>
      <label htmlFor="apiKey">API Key:</label>
      <input type="text" id="apiKey" name="apiKey" />
        
        <label htmlFor="customerId">Customer ID:</label>
        <input type="text" id="customerId" name="customerId" />

        <label htmlFor="customerType">Interpolation Mode:</label>
        <select name="languages" id="customerType">
        <option value="rollup">Monthly Rollup</option>
        <option value="missing_blocks">Fill Missing Blocks</option>
        </select>

        <label htmlFor="startDate">Start Date:</label>
        <input
          type="date"
          id="startDate"
          name="startDate"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />

        <label htmlFor="endDate">End Date:</label>
        <input
          type="date"
          id="endDate"
          name="endDate"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />

        <label htmlFor="location">Location:</label>
        <select name="cities" id="location">
        <option value="CPT">Cape Town</option>
        <option value="JHB">Johannesburg</option>
        <option value="LGS">Lagos</option>
        <option value="MMB">Mumbai</option>
        <option value="RLG">Raleigh</option>
        </select>

        <label htmlFor="coordinates">Coordinates (Latitude, Longitude):</label>
        <input
          type="text"
          id="coordinates"
          name="coordinates"
          placeholder="e.g., 37.7749, -122.4194"
          pattern="^[-+]?[0-9]{1,2}(?:\.[0-9]+)?,\s*[-+]?[0-9]{1,3}(?:\.[0-9]+)?$"
          title="Enter coordinates in the format: Latitude, Longitude"
        />



        <label htmlFor="uploadFile">Upload File:</label>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} id="uploadFile" name="uploadFile" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>

        <button className="submit-button" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default FormComponentInterpolate;
