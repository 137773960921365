import React, { useState, useEffect, useMemo } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import Layout from './components/Layout';
import Dashboard from './components/Dashboard';
import Chatbot from './components/Chatbot';
import OnDemand from './components/OnDemand';
import Forecasting from './components/Forecasting';
import Dispatch from './components/Dispatch';
import Settings from './components/Settings';
import Login from './components/Login';
import ProtectedRoute from './components/ProtectedRoute';
import LoadingScreen from './components/LoadingScreen';
import Checkout from './components/Checkout';
import './App.css';

const App = () => {
  console.log('App.js: Rendering...');

  // Auth0 hooks
  const { isAuthenticated, isLoading: authLoading, user, getAccessTokenSilently, logout } = useAuth0();

  // Role extraction logic
  const userRole = user && user['https://asoba.co/roles'];
  const userRoles = userRole ? (Array.isArray(userRole) ? userRole : [userRole]) : [];
  console.log('Role Assignment Debug:');
  console.log('Email:', user?.email);
  console.log('Raw Role from Auth0:', userRole);
  console.log('Final User Roles Array:', userRoles);

  // Loading state logic
  const [showLoadingScreen, setShowLoadingScreen] = useState(true);
  useEffect(() => {
    if (!authLoading) {
      const timer = setTimeout(() => setShowLoadingScreen(false), 300);
      return () => clearTimeout(timer);
    } else {
      setShowLoadingScreen(true);
    }
  }, [authLoading]);

  // Subscription success logic
  useEffect(() => {
    const handleSubscriptionSuccess = async () => {
      if (isAuthenticated && user) {
        const hasSuccessFlag = sessionStorage.getItem('subscriptionSuccess');
        const redirectPath = sessionStorage.getItem('redirectAfterAuth');
        if (hasSuccessFlag) {
          try {
            console.log('Subscription success detected, waiting for role update...');
            sessionStorage.removeItem('subscriptionSuccess');
            sessionStorage.removeItem('redirectAfterAuth');
            // Assume role update is handled by WebSocket or webhook
            console.log('Role update should be handled by WebSocket or webhook.');
            if (redirectPath) {
              window.location.href = redirectPath;
            }
          } catch (error) {
            console.error('Error during subscription verification:', error);
            window.location.href = '/checkout';
          }
        }
      }
    };

    handleSubscriptionSuccess();
  }, [isAuthenticated, user]);

  // Layout props
  const layoutProps = useMemo(() => ({
    isCollapsed: false,
    toggleCollapse: () => {},
    logout: () => logout({ returnTo: window.location.origin })
  }), [logout]);

  console.log('App.js: Validating auth and roles...');
  console.log('isAuthenticated:', isAuthenticated);
  console.log('authLoading:', authLoading);
  console.log('user:', user);
  console.log('userRole:', userRole);
  console.log('userRoles:', userRoles);

  return (
    <>
      {showLoadingScreen && <LoadingScreen />}
      <Routes>
        <Route
          path="/"
          element={isAuthenticated ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/*"
          element={
            <Layout {...layoutProps}>
              <Routes>
                <Route
                  path="/dashboard"
                  element={isAuthenticated ? <Dashboard /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/on-demand"
                  element={
                    <ProtectedRoute allowedRoles={['admin']} userRoles={userRoles}>
                      <OnDemand />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/forecasting"
                  element={
                    <ProtectedRoute allowedRoles={['admin']} userRoles={userRoles}>
                      <Forecasting />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/dispatch"
                  element={
                    <ProtectedRoute allowedRoles={['admin']} userRoles={userRoles}>
                      <Dispatch />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="/chatbot"
                  element={
                    userRoles.includes('basic') ? (
                      <Navigate to="/checkout" replace />
                    ) : (
                      <ProtectedRoute allowedRoles={['ondemand-alpha', 'admin']} userRoles={userRoles}>
                        <Chatbot />
                      </ProtectedRoute>
                    )
                  }
                />
                <Route
                  path="/checkout"
                  element={isAuthenticated ? <Checkout /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/settings"
                  element={isAuthenticated ? <Settings /> : <Navigate to="/" replace />}
                />
                <Route
                  path="/unauthorized"
                  element={
                    <div className="unauthorized-container">
                      <h2>Unauthorized Access</h2>
                      <p>You don't have permission to access this page.</p>
                      <button onClick={() => window.history.back()}>Go Back</button>
                    </div>
                  }
                />
                <Route path="*" element={<Navigate to="/dashboard" replace />} />
              </Routes>
            </Layout>
          }
        />
      </Routes>
    </>
  );
};

export default App;

/*
CHANGELOG:
1) Removed Documentation from side nav (now directly on the Dashboard)
2) Added Checkout component and route
3) Modified Chatbot route to redirect to Checkout for users without ondemand-alpha or admin roles
4) Added explicit unauthorized route
5) Updated fallback route to redirect to dashboard
6) Added authentication check for checkout route
7) Modified Dashboard route to be accessible to all authenticated users
8) Improved role handling to properly handle users with no roles
*/
