import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';

// Updated markers array with realistic 24-hour load curves and solar production curves for inverters.
// Each node’s loadData (and productionData for inverters) is unique.
const markers = [
  {
    id: 1,
    name: "Substation 1",
    position: [27.0473, -26.2041],
    city: "Johannesburg",
    deviceType: "Substation",
    hierarchyLevel: 1,
    zoomLevel: 4,
    // Realistic 24-hour load curve for a substation in Watts
    loadData: [13000, 12500, 12000, 11800, 11500, 13000, 15000, 17000, 19000, 21000, 23000, 24000, 24500, 24000, 23000, 22000, 21000, 20000, 19000, 18000, 17000, 16000, 15000, 14000],
    productionData: null
  },
  {
    id: 2,
    name: "Mini-Substation 1",
    position: [29.0549, -26.2025],
    city: "Johannesburg",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 4,
    // Realistic daily load curve for a mini-substation
    loadData: [4000, 3800, 3700, 3600, 3500, 3600, 4000, 4500, 5000, 5500, 6000, 6200, 6300, 6200, 6000, 5800, 5600, 5400, 5200, 5000, 4800, 4600, 4400, 4200],
    productionData: null
  },
  {
    id: 3,
    name: "Feeder 1",
    position: [28.9445, -26.1958],
    city: "Johannesburg",
    deviceType: "Feeder",
    hierarchyLevel: 3,
    zoomLevel: 6,
    // Feeder load curve with moderate consumption values
    loadData: [1500, 1450, 1400, 1380, 1350, 1400, 1550, 1700, 1850, 2000, 2100, 2150, 2200, 2150, 2100, 2050, 2000, 1950, 1900, 1850, 1800, 1750, 1700, 1650],
    productionData: null
  },
  {
    id: 4,
    name: "Inverter 1",
    position: [28.100, -26.1892],
    city: "Johannesburg",
    deviceType: "Inverter",
    hierarchyLevel: 6,
    zoomLevel: 8,
    // Inverters typically have lower load numbers and a solar production profile.
    loadData: [800, 780, 760, 750, 740, 750, 800, 900, 1000, 1100, 1200, 1250, 1280, 1250, 1200, 1150, 1100, 1050, 1000, 950, 900, 850, 820, 800],
    productionData: [0, 0, 0, 0, 0, 50, 150, 300, 500, 650, 800, 900, 950, 900, 850, 700, 500, 300, 150, 50, 0, 0, 0, 0]
  },
  {
    id: 5,
    name: "Smart Meter 1",
    position: [27.8765, -26.1826],
    city: "Johannesburg",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Residential smart meter load curve with low consumption values
    loadData: [100, 95, 90, 88, 85, 87, 95, 110, 130, 150, 170, 180, 185, 180, 170, 160, 150, 140, 130, 120, 110, 105, 102, 100],
    productionData: null
  },
  {
    id: 6,
    name: "Substation 2",
    position: [20.7232, -33.9249],
    city: "Cape Town",
    deviceType: "Substation",
    hierarchyLevel: 1,
    zoomLevel: 4,
    // Cape Town substation load curve with a higher daytime demand
    loadData: [15000, 14500, 14000, 13800, 13500, 15000, 17000, 19000, 21000, 23000, 25000, 26000, 26500, 26000, 25000, 24000, 23000, 22000, 21000, 20000, 19000, 18000, 17000, 16000],
    productionData: null
  },
  {
    id: 7,
    name: "Mini-Substation 2",
    position: [19.5280, -33.9220],
    city: "Cape Town",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 6,
    // Mini-substation curve for Cape Town scaled down from substations
    loadData: [5000, 4800, 4700, 4600, 4500, 4600, 5000, 5500, 6000, 6500, 7000, 7200, 7300, 7200, 7000, 6800, 6600, 6400, 6200, 6000, 5800, 5600, 5400, 5200],
    productionData: null
  },
  {
    id: 8,
    name: "Mini-Substation 3",
    position: [18.1235, -33.1291],
    city: "Cape Town",
    deviceType: "Mini-Substation",
    hierarchyLevel: 2,
    zoomLevel: 6,
    // A second mini-substation with a slightly different curve
    loadData: [5200, 5000, 4900, 4800, 4700, 4800, 5200, 5700, 6200, 6700, 7200, 7400, 7500, 7400, 7200, 7000, 6800, 6600, 6400, 6200, 6000, 5800, 5600, 5400],
    productionData: null
  },
  {
    id: 9,
    name: "Feeder 2",
    position: [18.993, -33.123],
    city: "Cape Town",
    deviceType: "Feeder",
    hierarchyLevel: 3,
    zoomLevel: 6,
    // Feeder load curve for Cape Town
    loadData: [1800, 1750, 1700, 1680, 1650, 1700, 1850, 2000, 2150, 2300, 2400, 2450, 2500, 2450, 2400, 2350, 2300, 2250, 2200, 2150, 2100, 2050, 2000, 1950],
    productionData: null
  },
  {
    id: 10,
    name: "Inverter 2",
    position: [20.0000, -33.8888],
    city: "Cape Town",
    deviceType: "Inverter",
    hierarchyLevel: 4,
    zoomLevel: 8,
    // Inverter load and production curves for Cape Town
    loadData: [1800, 1750, 1700, 1680, 1650, 1700, 1850, 2000, 2150, 2300, 2400, 2450, 2500, 2450, 2400, 2350, 2300, 2250, 2200, 2150, 2100, 2050, 2000, 1950],
    productionData: [0, 0, 0, 0, 0, 60, 180, 360, 600, 780, 900, 980, 1020, 980, 920, 840, 720, 600, 480, 360, 240, 120, 60, 0]
  },
  {
    id: 11,
    name: "Meter 2",
    position: [18.9999, -33.4454],
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8,
    // Residential meter load curve for Cape Town with slightly higher base consumption
    loadData: [110, 105, 100, 98, 95, 97, 105, 120, 140, 160, 180, 190, 195, 190, 180, 170, 160, 150, 140, 130, 120, 115, 112, 110],
    productionData: null
  },
  {
    id: 12,
    name: "Meter 3",
    position: [18.666, -32.9275],
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8,
    // Another residential meter curve with distinct values
    loadData: [105, 100, 95, 93, 90, 92, 100, 115, 135, 155, 175, 185, 190, 185, 175, 165, 155, 145, 135, 125, 115, 110, 107, 105],
    productionData: null
  },
  {
    id: 13,
    name: "Meter 4",
    position: [18.8888, -33.3333],
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8,
    // A distinct meter load curve variation
    loadData: [115, 110, 105, 103, 100, 102, 110, 125, 145, 165, 185, 195, 200, 195, 185, 175, 165, 155, 145, 135, 125, 120, 117, 115],
    productionData: null
  },
  {
    id: 14,
    name: "Meter 5",
    position: [20.0000, -31.9280],
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8,
    // Another meter load curve variation
    loadData: [120, 115, 110, 108, 105, 107, 115, 130, 150, 170, 190, 200, 205, 200, 190, 180, 170, 160, 150, 140, 130, 125, 122, 120],
    productionData: null
  },
  {
    id: 15,
    name: "Meter 6",
    position: [18.4310, -34.1290],
    city: "Cape Town",
    deviceType: "Meter",
    hierarchyLevel: 5,
    zoomLevel: 8,
    // Meter load curve with a slight upward shift for variation
    loadData: [125, 120, 115, 113, 110, 112, 120, 135, 155, 175, 195, 205, 210, 205, 195, 185, 175, 165, 155, 145, 135, 130, 127, 125],
    productionData: null
  },
  {
    id: 101,
    name: "Inverter 1",
    position: [-8.0, 12.75],
    city: "Siby",
    deviceType: "Inverter",
    hierarchyLevel: 6,
    zoomLevel: 8,
    // Siby inverter load curve with lower absolute values
    loadData: [70, 68, 66, 65, 64, 70, 80, 100, 120, 140, 150, 155, 158, 155, 150, 145, 140, 135, 130, 125, 120, 115, 110, 105],
    productionData: [0, 0, 0, 0, 0, 10, 30, 70, 110, 150, 180, 200, 220, 200, 180, 160, 140, 100, 60, 20, 0, 0, 0, 0]
  },
  {
    id: 102,
    name: "Inverter 2",
    position: [-8.01, 12.74],
    city: "Siby",
    deviceType: "Inverter",
    hierarchyLevel: 6,
    zoomLevel: 8,
    // Slightly different load curve for Siby inverter 2
    loadData: [72, 70, 68, 67, 66, 72, 82, 102, 122, 142, 152, 157, 160, 157, 152, 147, 142, 137, 132, 127, 122, 117, 112, 107],
    productionData: [0, 0, 0, 0, 0, 9, 28, 65, 100, 135, 160, 180, 200, 180, 160, 140, 120, 80, 50, 15, 0, 0, 0, 0]
  },
  {
    id: 103,
    name: "Smart Meter 1",
    position: [-8.0, 12.75],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Distinct smart meter curve for Siby
    loadData: [50, 48, 46, 45, 44, 45, 50, 55, 60, 65, 70, 72, 73, 72, 70, 68, 66, 64, 62, 60, 58, 56, 54, 52],
    productionData: null
  },
  {
    id: 104,
    name: "Smart Meter 2",
    position: [-8.01, 12.76],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Variation of smart meter curve for Siby
    loadData: [51, 49, 47, 46, 45, 46, 51, 56, 61, 66, 71, 73, 74, 73, 71, 69, 67, 65, 63, 61, 59, 57, 55, 53],
    productionData: null
  },
  {
    id: 105,
    name: "Smart Meter 3",
    position: [-8.02, 12.74],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another unique smart meter load curve for Siby
    loadData: [52, 50, 48, 47, 46, 47, 52, 57, 62, 67, 72, 74, 75, 74, 72, 70, 68, 66, 64, 62, 60, 58, 56, 54],
    productionData: null
  },
  {
    id: 106,
    name: "Smart Meter 4",
    position: [-8.03, 12.73],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another variation for Siby smart meter load data
    loadData: [53, 51, 49, 48, 47, 48, 53, 58, 63, 68, 73, 75, 76, 75, 73, 71, 69, 67, 65, 63, 61, 59, 57, 55],
    productionData: null
  },
  {
    id: 107,
    name: "Smart Meter 5",
    position: [-8.02, 12.77],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Unique smart meter curve variation for Siby
    loadData: [54, 52, 50, 49, 48, 49, 54, 59, 64, 69, 74, 76, 77, 76, 74, 72, 70, 68, 66, 64, 62, 60, 58, 56],
    productionData: null
  },
  {
    id: 108,
    name: "Smart Meter 6",
    position: [-8.04, 12.78],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another distinct smart meter load pattern for Siby
    loadData: [55, 53, 51, 50, 49, 50, 55, 60, 65, 70, 75, 77, 78, 77, 75, 73, 71, 69, 67, 65, 63, 61, 59, 57],
    productionData: null
  },
  {
    id: 109,
    name: "Smart Meter 7",
    position: [-8.05, 12.75],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Unique curve for Siby smart meter 7
    loadData: [56, 54, 52, 51, 50, 51, 56, 61, 66, 71, 76, 78, 79, 78, 76, 74, 72, 70, 68, 66, 64, 62, 60, 58],
    productionData: null
  },
  {
    id: 110,
    name: "Smart Meter 8",
    position: [-8.0, 12.78],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another distinct smart meter load curve for Siby
    loadData: [57, 55, 53, 52, 51, 52, 57, 62, 67, 72, 77, 79, 80, 79, 77, 75, 73, 71, 69, 67, 65, 63, 61, 59],
    productionData: null
  },
  {
    id: 111,
    name: "Smart Meter 9",
    position: [-8.01, 12.75],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another unique smart meter curve for Siby
    loadData: [58, 56, 54, 53, 52, 53, 58, 63, 68, 73, 78, 80, 81, 80, 78, 76, 74, 72, 70, 68, 66, 64, 62, 60],
    productionData: null
  },
  {
    id: 112,
    name: "Smart Meter 10",
    position: [-8.03, 12.76],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Siby smart meter with a distinct load profile variation
    loadData: [59, 57, 55, 54, 53, 54, 59, 64, 69, 74, 79, 81, 82, 81, 79, 77, 75, 73, 71, 69, 67, 65, 63, 61],
    productionData: null
  },
  {
    id: 113,
    name: "Smart Meter 11",
    position: [-8.04, 12.77],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another variation for Siby smart meter load data
    loadData: [60, 58, 56, 55, 54, 55, 60, 65, 70, 75, 80, 82, 83, 82, 80, 78, 76, 74, 72, 70, 68, 66, 64, 62],
    productionData: null
  },
  {
    id: 114,
    name: "Smart Meter 12",
    position: [-8.05, 12.78],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Unique smart meter curve for Siby with another variation
    loadData: [61, 59, 57, 56, 55, 56, 61, 66, 71, 76, 81, 83, 84, 83, 81, 79, 77, 75, 73, 71, 69, 67, 65, 63],
    productionData: null
  },
  {
    id: 115,
    name: "Smart Meter 13",
    position: [-8.0, 12.77],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Another distinct load profile for a Siby smart meter
    loadData: [62, 60, 58, 57, 56, 57, 62, 67, 72, 77, 82, 84, 85, 84, 82, 80, 78, 76, 74, 72, 70, 68, 66, 64],
    productionData: null
  },
  {
    id: 116,
    name: "Smart Meter 14",
    position: [-8.01, 12.76],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Variation for Siby smart meter load profile with a slight offset
    loadData: [63, 61, 59, 58, 57, 58, 63, 68, 73, 78, 83, 85, 86, 85, 83, 81, 79, 77, 75, 73, 71, 69, 67, 65],
    productionData: null
  },
  {
    id: 117,
    name: "Smart Meter 15",
    position: [-8.02, 12.74],
    city: "Siby",
    deviceType: "Meter",
    hierarchyLevel: 7,
    zoomLevel: 8,
    // Final distinct smart meter load curve for Siby
    loadData: [64, 62, 60, 59, 58, 59, 64, 69, 74, 79, 84, 86, 87, 86, 84, 82, 80, 78, 76, 74, 72, 70, 68, 66],
    productionData: null
  },
  {
    id: 118,
    name: "Substation",
    position: [-8.015, 12.75],
    city: "Siby",
    deviceType: "Substation",
    hierarchyLevel: 1,
    zoomLevel: 4,
    // Updated Siby substation load curve with a realistic daily profile
    loadData: [8000, 7800, 7600, 7500, 7400, 7500, 8000, 9000, 10000, 11000, 12000, 13000, 13500, 13000, 12500, 12000, 11500, 11000, 10500, 10000, 9500, 9000, 8500, 8000],
    productionData: null
  }
];

const getColorForDeviceType = (deviceType) => {
  switch (deviceType) {
    case 'Substation':
      return '#FF0000'; // Red for substations
    case 'Mini-Substation':
      return '#FFA500'; // Orange for mini-substations
    case 'Feeder':
      return '#00FF00'; // Green for feeders
    case 'Inverter':
      return '#0000FF'; // Blue for inverters
    case 'Meter':
      return '#FFFF00'; // Yellow for meters
    default:
      return '#FFFFFF'; // Default color
  }
};

const MarkersComponent = ({ map, onMarkerClick }) => {
  useEffect(() => {
    if (!map || !map.getContainer()) return; // Ensure the map container is available

    const markerInstances = []; // Store marker instances for proper cleanup

    const addMarkers = () => {
      markers.forEach(marker => {
        // Check if the current zoom level matches or exceeds the marker's zoom level
        if (map.getZoom() >= marker.zoomLevel) {
          // Create a marker instance with popup
          const markerInstance = new mapboxgl.Marker({ color: getColorForDeviceType(marker.deviceType) })
            .setLngLat(marker.position)
            .setPopup(
              new mapboxgl.Popup({ offset: 25 })
                .setHTML(`
                  <h3>${marker.name}</h3>
                  <p>City: ${marker.city}</p>
                  <p>Network Level: ${marker.deviceType}</p>
                  <p>Avg Hourly Load: ${calculateAverageLoad(marker.loadData)}</p>
                `)
            )
            .addTo(map); // Add the marker and popup to the map

          // Default Mapbox popup behavior on click
          markerInstance.getElement().addEventListener('click', () => {
            onMarkerClick(marker); // Pass marker data to the parent component
          });

          markerInstances.push(markerInstance); // Store for cleanup
        }
      });
    };

    const handleZoomChange = () => {
      // Clear existing markers
      markerInstances.forEach(markerInstance => markerInstance.remove());
      markerInstances.length = 0; // Reset the array

      // Re-add markers based on the new zoom level
      addMarkers();
    };

    // Add markers initially based on the zoom level
    addMarkers();

    // Listen for zoom changes
    map.on('zoom', handleZoomChange);

    // Cleanup function to remove the markers when the component unmounts
    return () => {
      map.off('zoom', handleZoomChange); // Remove zoom listener
      markerInstances.forEach(markerInstance => markerInstance.remove()); // Properly remove all markers
    };
  }, [map, onMarkerClick]);

  return null;
};

// Helper function to calculate the average load
const calculateAverageLoad = (loadData) => {
  const sum = loadData.reduce((acc, value) => acc + value, 0);
  return Math.round(sum / loadData.length);
};

export default MarkersComponent;

/*
Changelog:
- Replaced all dummy loadData arrays with realistic 24-hour load curves for each electricity network node type.
  - Substations now show higher loads during peak hours with lower loads at night.
  - Mini-substations and feeders use scaled-down versions of the substation curve.
  - Inverters now include both realistic load curves and matching solar production curves (zero at night, peaking midday).
  - Smart Meters now display low, residential-level consumption curves with slight variations between each meter to ensure uniqueness.
- ProductionData arrays for inverters have been updated to mimic realistic solar generation.
- All other properties (id, name, position, city, deviceType, hierarchyLevel, zoomLevel) remain unchanged.
- Inline comments have been added to indicate where changes were made.
*/
