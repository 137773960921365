// src/Chatbot.js
import React, { useState, useEffect, useRef } from "react";
import ReactMarkdown from "react-markdown";
import "../App.css";
import { FiSend } from "react-icons/fi";
import { useLocation } from "react-router-dom";

// Use the environment variable for the API URL.
// In production, set REACT_APP_API_URL to your deployed API endpoint (e.g., https://<your-api-id>.execute-api.us-east-1.amazonaws.com/dev).
const API_URL = process.env.REACT_APP_API_URL || "http://localhost:5000";

const Chatbot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const chatEndRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    document.body.classList.add("chat-active");
    return () => document.body.classList.remove("chat-active");
  }, []);

  useEffect(() => {
    // Check for success URL parameters
    const params = new URLSearchParams(location.search);
    const success = params.get('success');
    const sessionId = params.get('session_id');

    if (success === 'true' && sessionId) {
      // Add welcome message for new subscribers
      setMessages([
        {
          role: "assistant",
          content: [{ text: "Welcome to Ona On-Demand! Your subscription is now active. How can I help you today?" }]
        }
      ]);
    }
  }, [location]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages, errorMessage]);

  // Build payload from conversation
  const buildPayload = (localMessages) => {
    return {
      messages: localMessages,
      inferenceConfig: {
        maxTokens: 512,
        temperature: 0.7,
        topP: 0.9,
      },
    };
  };

  // Call backend using the API_URL from the environment variable
  const callBedrockBackend = async (localMessages) => {
    const payload = buildPayload(localMessages);
    console.log("Client -> Server payload:", JSON.stringify(payload, null, 2));
  
    const response = await fetch(`${API_URL}/api/bedrock`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });

    console.log("Response status:", response.status);

    if (!response.ok) {
      const errorText = await response.text();
      console.error("Error response text from server:", errorText);
      throw new Error(errorText || "Error invoking Bedrock model");
    }

    const data = await response.json();
    console.log("Server -> Client data:", data);

    // Extract text from model response
    const aiText = data?.aiOutput ?? "";
  return aiText;
  };

  // Handle user sending a message
  const handleSendMessage = async () => {
    if (!input.trim() || loading) return;

    setErrorMessage(null);
    setLoading(true);

    const userMessage = {
      role: "user",
      content: [{ text: input.trim() }],
    };

    // local copy of updated messages
    const updatedMessages = [...messages, userMessage];
    setMessages(updatedMessages);
    setInput("");

    try {
      const aiText = await callBedrockBackend(updatedMessages);

      if (!aiText.trim()) {
        setErrorMessage("No AI output (empty string).");
      } else {
        const assistantMessage = {
          role: "assistant",
          content: [{ text: aiText }],
        };
        const newConversation = [...updatedMessages, assistantMessage];
        setMessages(newConversation);
      }
    } catch (error) {
      console.error("AI Error:", error);
      setErrorMessage("Error from AI: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Send on Enter
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <h2>Policy Analyst</h2>
      </div>

      <div className="chat-messages">
        {messages.length === 0 && !errorMessage && (
          <div className="chat-placeholder">Let's talk energy policy!</div>
        )}

        {messages.map((msg, index) => (
          <div key={index} className={`chat-message ${msg.role}`}>
            {msg.role === "assistant" ? (
              <ReactMarkdown className="assistant-markdown">
                {msg.content[0].text}
              </ReactMarkdown>
            ) : (
              <p>{msg.content[0].text}</p>
            )}
          </div>
        ))}

        {errorMessage && (
          <div className="chat-message error">
            <p style={{ color: "red", whiteSpace: "pre-wrap" }}>{errorMessage}</p>
          </div>
        )}

        <div ref={chatEndRef} />
      </div>

      <div className="chat-input-container">
        {loading && <div className="loading-indicator">AI is thinking...</div>}

        <textarea
          placeholder="Message AI..."
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={handleKeyDown}
          className="chat-textarea"
          rows="1"
          disabled={loading}
        />

        <button
          onClick={handleSendMessage}
          className="send-button"
          disabled={loading}
        >
          <FiSend />
        </button>
      </div>
    </div>
  );
};

export default Chatbot;
