import React, { useState, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkSlug from 'remark-slug';
import '../App.css'; // Ensure this file has the same styles as OnDemand

function Dashboard() {
  // Main tabs: 'dashboard', 'gettingstarted', 'documentation'
  const [activeTab, setActiveTab] = useState('dashboard');

  // Sub-tabs for the Getting Started section
  const [activeSubTab, setActiveSubTab] = useState('quickstart');

  // State to store markdown content for Documentation
  const [markdownContent, setMarkdownContent] = useState('');

  // Fetch markdown content when Documentation tab is active
  useEffect(() => {
    if (activeTab === 'documentation') {
      fetch('/documentation.md')
        .then((response) => response.text())
        .then((text) => setMarkdownContent(text));
    }
  }, [activeTab]);

  // Render sub-content for the Getting Started tab
  const renderSubContent = () => {
    switch (activeSubTab) {
      case 'quickstart':
        return (
          <div className="code-container">
            <h3>Quick Start</h3>
            <p>Follow these steps to quickly integrate our API into your project:</p>
            <pre>
              <code>
{`import requests

api_key = 'YOUR_API_KEY'
base_url = 'https://api.example.com'
endpoint = '/ingest/data'
headers = {
  'Authorization': f'Bearer {api_key}',
  'Content-Type': 'application/json'
}
payload = {
  'device_id': '12345',
  'timestamp': '2024-01-01T00:00:00Z',
  'total_load': 500,
  'customer_id': '28005'
}
response = requests.post(f'{base_url}{endpoint}', headers=headers, json=payload)
print(response.json())
`}
              </code>
            </pre>
          </div>
        );
      case 'learnmore':
        return (
          <div>
            <h3>Learn More</h3>
            <p>Detailed information and best practices for integrating our services.</p>
          </div>
        );
      case 'utilityinverter':
        return (
          <div>
            <h3>Getting Utility/Inverter Data</h3>
            <p>Instructions for accessing utility and inverter-specific data.</p>
          </div>
        );
      default:
        return null;
    }
  };

  // Render main content based on active main tab
  const renderContent = () => {
    switch (activeTab) {
      case 'dashboard':
        /* Replaced the placeholder with a new layout: 
           1) Two 50/50 columns with some content
           2) A full-width billing section
           3) A full-width table section
        */
        return (
          <div className="dashboard-container">
            {/* Top 50/50 columns */}
            <div className="top-two-cols" style={{ display: 'flex', gap: '20px', marginBottom: '20px' }}>
              <div className="left-col" style={{
                flex: 1,
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px'
              }}>
                <h2>Welcome to Ona!</h2>
                <p>Follow these steps to get the most out of your experience. Give us a shout at <a href="mailto:support@asoba.co">support@asoba.co</a> if you get stuck along the way!</p>
                <ul>
                  <li>Configure Billing</li>
                  <li>Generate a Forecast</li>
                  <li>Consult the ClimatIQ Energy Policy Chatbot</li>
                </ul>
              </div>
              <div className="right-col" style={{
                flex: 1,
                border: '1px solid #ccc',
                borderRadius: '8px',
                padding: '20px'
              }}>
                <h2>Latest from Open Grid Initiative</h2>
                <ul>
                  <li>March NewsJack update now available! [March 1, 2025]</li>
                  <li>South Africa Electricity Act of 2025 now available [Feb 22, 2025]</li>
                  <li>US Executive Orders update now available [January 25, 2025]</li>
                </ul>
              </div>
            </div>

            {/* Full-width billing info section */}
            <div className="billing-section" style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '20px',
              marginBottom: '20px'
            }}>
              <h2>Billing</h2>
              <p>You have $30.00 usage this month.</p>
              <button style={{ marginRight: '10px' }}>View Invoices</button>
              <button>Manage Billing</button>
            </div>

            {/* Full-width table section */}
            <div className="predictions-table-section" style={{
              border: '1px solid #ccc',
              borderRadius: '8px',
              padding: '20px'
            }}>
              <h2>Recent predictions</h2>
              <table style={{ width: '100%', borderCollapse: 'collapse', marginTop: '10px' }}>
                <thead>
                  <tr style={{ backgroundColor: '#f4f4f4' }}>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>ID</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Model</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Status</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Cost</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Running</th>
                    <th style={{ border: '1px solid #ddd', padding: '8px' }}>Start Time</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>abc123</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Flux 1.2 Ultra</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Success</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>$0.90</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>3 min</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>2 hours ago</td>
                  </tr>
                  <tr>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>xyz789</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Flux Schnell</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>Running</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>$0.45</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>1 min</td>
                    <td style={{ border: '1px solid #ddd', padding: '8px' }}>5 minutes ago</td>
                  </tr>
                  {/* Add more rows as needed */}
                </tbody>
              </table>
            </div>
          </div>
        );
      case 'gettingstarted':
        return (
          <div className="getting-started">
            <div className="left-tabs">
              <ul>
                <li
                  onClick={() => setActiveSubTab('quickstart')}
                  className={activeSubTab === 'quickstart' ? 'active' : ''}
                >
                  Quick Start
                </li>
                <li
                  onClick={() => setActiveSubTab('learnmore')}
                  className={activeSubTab === 'learnmore' ? 'active' : ''}
                >
                  Learn More
                </li>
                <li
                  onClick={() => setActiveSubTab('utilityinverter')}
                  className={activeSubTab === 'utilityinverter' ? 'active' : ''}
                >
                  Getting Utility/Inverter Data
                </li>
              </ul>
            </div>
            <div className="content-area">{renderSubContent()}</div>
          </div>
        );
      case 'documentation':
        return (
          <div className="markdown-content">
            <ReactMarkdown remarkPlugins={[remarkSlug]}>
              {markdownContent}
            </ReactMarkdown>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="on-demand"> {/* Using the same container class as OnDemand for styling */}
      <div className="tabs">
        <button
          className={activeTab === 'dashboard' ? 'active' : ''}
          onClick={() => setActiveTab('dashboard')}
        >
          Dashboard
        </button>
        <button
          className={activeTab === 'gettingstarted' ? 'active' : ''}
          onClick={() => setActiveTab('gettingstarted')}
        >
          Getting Started
        </button>
        <button
          className={activeTab === 'documentation' ? 'active' : ''}
          onClick={() => setActiveTab('documentation')}
        >
          Documentation
        </button>
      </div>
      {renderContent()}
    </div>
  );
}

export default Dashboard;

/*
CHANGELOG (most recent edits at bottom):
--------------------------------------------------------------------------------
1) Preserved the existing top-level tab structure: Dashboard, Getting Started, Documentation.
2) Replaced the placeholder "dashboard" content with a new layout:
   - top-two-cols: Two 50/50 columns with separate borders (left-col, right-col).
   - billing-section: A full-width bordered area for billing info.
   - predictions-table-section: A full-width bordered area containing a sample table.
3) Retained the Getting Started and Documentation tabs as they were, ensuring 
   existing functionality remains intact. 
4) Added inline comments where code was changed or newly added. 
*/ 
