import React from 'react';
import { createRoot } from 'react-dom/client';  // import createRoot for React 18
import { Auth0Provider } from '@auth0/auth0-react';
import { UserProvider } from './components/UserContext'; // Import the UserProvider
import ErrorBoundary from './components/ErrorBoundary';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './index.css';
import { BrowserRouter as Router } from 'react-router-dom';

// Auth0 configuration
const domain = process.env.REACT_APP_AUTH0_DOMAIN;
const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

// Debug environment variables
console.log('Auth0 Configuration:', {
  domain,
  clientId,
  audience,
  env: process.env
});

// Define custom onRedirectCallback to navigate to /dashboard after login
const onRedirectCallback = (appState) => {
  // Use the returnTo URL from appState if it exists, otherwise go to dashboard
  const returnTo = appState?.returnTo || '/dashboard';
  window.history.pushState({}, '', returnTo);
};

// Use createRoot to render the app for React 18
const container = document.getElementById('root');
const root = createRoot(container);

// Add global error handler for uncaught errors
window.addEventListener('error', (event) => {
  console.error('Global error:', event.error);
});

// Add global handler for unhandled promise rejections
window.addEventListener('unhandledrejection', (event) => {
  console.error('Unhandled promise rejection:', event.reason);
});

root.render(
  <Router>
    <ErrorBoundary>
      <Auth0Provider
        domain={domain}
        clientId={clientId}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: audience,
          scope: 'openid profile email'
        }}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
      >
        {/* Wrap App with UserProvider */}
        <UserProvider>
          <App />
        </UserProvider>
      </Auth0Provider>
    </ErrorBoundary>
  </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
