import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ allowedRoles, userRoles, children }) => {
  console.log('ProtectedRoute.js: Validating roles...');
  console.log('userRoles:', userRoles, 'allowedRoles:', allowedRoles);

  // If no roles have been passed in yet, return null (or a loader) until they are available.
  if (userRoles.length === 0) {
    console.log('ProtectedRoute.js: Waiting for role validation...');
    return null;
  }

  // Check if the user has at least one allowed role.
  const hasAccess = allowedRoles.some((role) => userRoles.includes(role));
  if (!hasAccess) {
    console.log('ProtectedRoute.js: Unauthorized. Redirecting to /unauthorized...');
    return <Navigate to="/unauthorized" replace />;
  }

  console.log('ProtectedRoute.js: Authorized. Rendering child components...');
  return children;
};

export default ProtectedRoute;

/*
CHANGELOG:
1) Removed the useUserContext import and its usage.
2) Now expects a "userRoles" prop to be passed in from App.js.
3) Retains existing logic: if no roles are available, waits (returns null); if roles do not match allowedRoles, redirects.
*/
