import { useAuth0 } from "@auth0/auth0-react";
import React, { useState, useEffect } from 'react';
import Profile from './Profile';
import { FaPencilAlt } from 'react-icons/fa';
import Modal from './Modal';
import AWS from 'aws-sdk';  // AWS SDK for interacting with API Gateway

const Settings = () => {
  const [activeTab, setActiveTab] = useState('user');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isEditMode, setIsEditMode] = useState(false);
  const [modalType, setModalType] = useState('');
  const [apiKeys, setApiKeys] = useState([]);  // For storing API keys
  const [newApiKeyName, setNewApiKeyName] = useState('');  // For creating new API keys
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();  // Get Auth0 user
  const [clientId, setClientId] = useState(null);  // Store clientid for current user

  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
    region: process.env.REACT_APP_AWS_REGION,
  });

  // Add state for collapsible section (remains for integrations panel)
  const [isCollapsibleOpen, setIsCollapsibleOpen] = useState(false);
  const apiGateway = new AWS.APIGateway({ region: 'af-south-1' });  // Initialize AWS API Gateway
  const apiUrl = process.env.REACT_APP_API_URL; // Base URL from your .env for auth0

  // Fetch the clientid based on the Auth0 sub
  const fetchClientId = async () => {
    try {
      const { sub } = user;  // Get the Auth0 sub (unique user identifier)
      const response = await fetch(`${apiUrl}/get-clientid`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getAccessTokenSilently()}`,  // Include Auth0 token for auth
        },
        body: JSON.stringify({ sub })  // Send the Auth0 sub to your backend
      });
      const data = await response.json();
      setClientId(data.clientid);  // Set the clientid in state
      console.log('Client ID fetched successfully:', data.clientid);
    } catch (error) {
      console.error('Error fetching clientid:', error);
    }
  };

  useEffect(() => {
    if (isAuthenticated && user) {
      fetchClientId();  // Fetch clientid after login
    }
  }, [isAuthenticated, user]);

  useEffect(() => {
    if (clientId) {
      fetchApiKeysForUser();  // Fetch API keys after clientid is fetched
    }
  }, [clientId]);

  // Toggle the collapsible section
  const toggleCollapsible = () => {
    setIsCollapsibleOpen(!isCollapsibleOpen);
  };

  // Create a new API key using AWS API Gateway
  const createApiKey = async (formData) => {
    const { apiKeyName } = formData;
    console.log('Sending API key request with name:', apiKeyName);  // Debugging point
    try {
      const apiKeyResponse = await apiGateway.createApiKey({
        name: apiKeyName,
        enabled: true,
        generateDistinctId: true,  // Ensures uniqueness
      }).promise();
      console.log('API Key created successfully:', apiKeyResponse);
      const apiKeyId = apiKeyResponse.id;
      // Associate API Key with a Usage Plan
      await apiGateway.createUsagePlanKey({
        keyId: apiKeyId,
        keyType: 'API_KEY',
        usagePlanId: '63wc53',  // Your usage plan ID
      }).promise();
      // Update Postgres with API key details
      await updateApiKeyInDatabase({
        apiKeyName,
        apiKeyValue: apiKeyResponse.value,  // This is the actual key value
      });
      setApiKeys([...apiKeys, apiKeyResponse]);  // Add new API key to the state
    } catch (error) {
      console.error('Error creating API key:', error);
    }
  };

  // Open modal for creating/editing entries (now only used for API Keys)
  const openModal = (type, entryData = null) => {
    setModalType(type);
    setIsEditMode(!!entryData);
    setModalData(entryData);
    setIsModalOpen(true);
  };

  // Close modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Save new or updated entry (only API Key in this version)
  const saveEntry = async (newData) => {
    console.log('saveEntry called with:', newData);
    if (modalType === 'API Key') {
      await createApiKey(newData);  // Create API key in AWS
      await updateApiKeyInDatabase(newData);  // Update API key in Postgres
    }
    closeModal();
  };

  // Function to fetch API keys for the current user
  const fetchApiKeysForUser = async () => {
    try {
      if (!clientId) {
        console.error('Client ID not available, skipping API key fetch');
        return;
      }
      const response = await fetch(`${apiUrl}/api-keys/${clientId}`, {
        method: 'GET',
        headers: {
          'Authorization': `Bearer ${await getAccessTokenSilently()}`,  // Auth header with Auth0 token
        },
      });
      const apiKeysData = await response.json();
      setApiKeys(apiKeysData);  // Update the apiKeys state with the fetched data
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

  // Function to update the client_api_keys table in Postgres
  const updateApiKeyInDatabase = async (apiKeyData) => {
    try {
      const userId = 'currentuserid';  // Replace with actual user ID logic
      const response = await fetch(`${apiUrl}/update-client-api-keys`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          clientId: userId,
          apiKeyName: apiKeyData.apiKeyName,
          apiKey: apiKeyData.apiKeyValue,
        }),
      });
      if (response.ok) {
        console.log('API key successfully updated in the database');
        fetchApiKeysForUser();  // Refresh the list of API keys
      } else {
        console.error('Failed to update API key in database');
      }
    } catch (error) {
      console.error('Error updating API key in database:', error);
    }
  };

  // Function to render the API keys table
  const renderApiKeysTable = () => (
    <table className="client-details-table">
      <thead>
        <tr>
          <th>API Key Name</th>
          <th>API Key</th>
        </tr>
      </thead>
      <tbody>
        {apiKeys.map((key) => (
          <tr key={key.id}>
            <td>{key.name}</td>
            <td>{key.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );

  // Function to open the modal for creating an API key
  const openApiKeyModal = () => {
    setNewApiKeyName('');  // Reset modal input
    setModalType('API Key');
    setIsModalOpen(true);  // Open modal
  };

  // Render content based on the active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'user':
        return <Profile />;
      case 'integrations':
        return (
          <div className="integrations-tab">
            <h2>API Integrations</h2>
            {/* Render the API Keys Table */}
            {renderApiKeysTable()}
            {/* Link to open modal for creating API key */}
            <a className="add-link" onClick={openApiKeyModal}>+ Create API Key</a>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="settings">
      <div className="tabs">
        <button className={activeTab === 'user' ? 'active' : ''} onClick={() => setActiveTab('user')}>User</button>
        <button className={activeTab === 'integrations' ? 'active' : ''} onClick={() => setActiveTab('integrations')}>Integrations</button>
      </div>
      <div className="settings-content">
        {renderContent()}
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={closeModal}
        onSave={saveEntry}
        entryData={modalData}
        isEdit={isEditMode}
        type={modalType}
      />
    </div>
  );
};

export default Settings;
