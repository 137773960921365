import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './Checkout.css';

const Logo = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    width="14px"
    height="16px"
    viewBox="0 0 14 16"
    version="1.1"
  >
    <defs />
    <g id="Flow" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        id="0-Default"
        transform="translate(-121.000000, -40.000000)"
        fill="#455BF1"
      >
        <path
          d="M127,50 L126,50 C123.238576,50 121,47.7614237 121,45 C121,42.2385763 123.238576,40 126,40 L135,40 L135,56 L133,56 L133,42 L129,42 L129,56 L127,56 L127,50 Z M127,48 L127,42 L126,42 C124.343146,42 123,43.3431458 123,45 C123,46.6568542 124.343146,48 126,48 L127,48 Z"
          id="Pilcrow"
        />
      </g>
    </g>
  </svg>
);

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { getAccessTokenSilently, isAuthenticated, loginWithRedirect } = useAuth0();
  
  useEffect(() => {
    // Check for query parameters
    const params = new URLSearchParams(location.search);
    const success = params.get('success');
    const canceled = params.get('canceled');
    
    if (success === 'true' && isAuthenticated) {
      console.log('Payment success detected, setting up redirect...');
      // Store success state and redirect path in sessionStorage
      sessionStorage.setItem('subscriptionSuccess', 'true');
      sessionStorage.setItem('redirectAfterAuth', '/chatbot?success=true');
      // Clear URL parameters to prevent re-processing
      window.history.replaceState({}, '', '/checkout');
    } else if (canceled === 'true') {
      setMessage("Payment cancelled. You can try again when you're ready.");
      // Clear URL parameters
      window.history.replaceState({}, '', '/checkout');
    }
  }, [location, isAuthenticated]);

  const handleSubmit = async (priceId) => {
    try {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { returnTo: window.location.pathname }
        });
        return;
      }

      setIsLoading(true);
      setMessage('');

      // Get the access token
      const token = await getAccessTokenSilently();

      console.log('API URL:', process.env.REACT_APP_API_URL);
      console.log('Full URL:', `${process.env.REACT_APP_API_URL}/create-checkout-session`);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
        body: JSON.stringify({
          priceId: priceId
        })
      });

      console.log('Response status:', response.status);
      console.log('Response headers:', Object.fromEntries(response.headers.entries()));
      
      let data;
      const contentType = response.headers.get('content-type');
      if (contentType && contentType.includes('application/json')) {
        data = await response.json();
      } else {
        const text = await response.text();
        console.log('Non-JSON response:', text);
        throw new Error(`Server returned non-JSON response: ${text}`);
      }

      if (!response.ok) {
        console.error('Error response:', data);
        throw new Error(data.message || `HTTP error! status: ${response.status}`);
      }

      // Redirect to Stripe Checkout
      window.location.href = data.url;
    } catch (error) {
      console.error('Detailed error:', error);
      setMessage(`Failed to start checkout: ${error.message}`);
      // Don't trigger logout on error
      setIsLoading(false);
    }
  };

  return (
    <section className="pricing-section">
      <h2 className="pricing-title">Choose Your Plan</h2>
      {message && (
        <div className={`message ${message.includes('cancelled') ? 'message-info' : 'message-error'}`}>
          {message}
        </div>
      )}
      <div className="pricing-grid">
        <div className="pricing-card">
          <div className="product">
            
            <div className="description">
              <h3>Monthly Plan</h3>
              <h5>$8/month</h5>
              <p className="plan-description">Full access to all features</p>
            </div>
          </div>
          <button 
            className="checkout-button" 
            onClick={() => handleSubmit('price_1R8fMlCW0fJkI6g8b1pqchpF')}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Subscribe Monthly'}
          </button>
        </div>

        <div className="pricing-card">
          <div className="product">
            
            <div className="description">
              <h3>Annual Plan</h3>
              <h5>$60/year</h5>
              <p className="plan-description">Full access to all features</p>
            </div>
          </div>
          <button 
            className="checkout-button featured" 
            onClick={() => handleSubmit('price_1R8fNPCW0fJkI6g8GpsKevKS')}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 'Subscribe Annually'}
          </button>
        </div>
      </div>
      
      <div className="features-comparison">
        <table>
          <thead>
            <tr>
              <th>Features</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Adaptive Query Handling</td>
              <td>The chatbot classifies incoming questions for accurate responses</td>
            </tr>
            <tr>
              <td>Session Continuation</td>
              <td>Retains conversation context across multiple interactions</td>
            </tr>
            <tr>
              <td>Multi-Format Data Coverage</td>
              <td>Pulls information from various sources for comprehensive policy views</td>
            </tr>
            <tr>
              <td>Adaptive Follow-up Questions</td>
              <td>Intelligently asks clarifying questions to provide more accurate and detailed responses</td>
            </tr>
            <tr>
              <td>Direct Citations</td>
              <td>Provides source references and citations for easy follow-up research</td>
            </tr>
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default Checkout; 