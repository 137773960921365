// OnaUploader.js
export default class OnaUploader {
    constructor(apiKey) {
      this.apiKey = apiKey;
      this.apiUploadUrl = process.env.REACT_APP_ONA_API_UPLOAD_URL;
      this.apiTrainUrl = process.env.REACT_APP_ONA_API_TRAIN_URL;
    }
  
    _getFormattedTimestamp() {
      const now = new Date();
      const pad = (n) => n.toString().padStart(2, '0');
      return (
        now.getFullYear().toString() +
        pad(now.getMonth() + 1) +
        pad(now.getDate()) +
        pad(now.getHours()) +
        pad(now.getMinutes()) +
        pad(now.getSeconds())
      );
    }
  
    async getPresignedUrl(customerId, region, location, manufacturer, filename) {
      const params = new URLSearchParams({
        customer_id: customerId,
        region,
        location,
        manufacturer,
        filename,
      });
      const url = `${this.apiUploadUrl}/upload_train?${params.toString()}`;
      const headers = {
        'x-api-key': this.apiKey,
        'Content-Type': 'application/json',
      };
  
      try {
        const response = await fetch(url, { method: 'POST', headers });
        if (!response.ok) {
          throw new Error(`Presign error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        return { success: true, data };
      } catch (error) {
        return { success: false, error: error.message };
      }
    }
  
    async uploadFile(file, presignedUrl) {
      try {
        const response = await fetch(presignedUrl, {
          method: 'PUT',
          headers: { 'Content-Type': 'text/csv' },
          body: file,
        });
        if (!response.ok) {
          throw new Error(`Upload error: ${response.status} ${response.statusText}`);
        }
        return { success: true };
      } catch (error) {
        return { success: false, error: error.message };
      }
    }
  
    async uploadFileToS3(file, customerId, region, location, manufacturer) {
      const originalFilename = file.name;
      const timestamp = this._getFormattedTimestamp();
      const newFilename = `${timestamp}_${originalFilename}`;
  
      // Step 1: get presigned URL
      const presignRes = await this.getPresignedUrl(
        customerId,
        region,
        location,
        manufacturer,
        newFilename
      );
      if (!presignRes.success) {
        return {
          step: 'presign',
          success: false,
          error: presignRes.error,
        };
      }
  
      const presignedUrl = presignRes.data?.presigned_url;
      if (!presignedUrl) {
        return {
          step: 'presign',
          success: false,
          error: 'No presigned URL returned from API.',
        };
      }
  
      // Step 2: upload file
      const uploadRes = await this.uploadFile(file, presignedUrl);
      if (!uploadRes.success) {
        return {
          step: 'upload',
          success: false,
          error: uploadRes.error,
        };
      }
  
      // If everything went fine:
      return {
        step: 'upload',
        success: true,
        presignedUrl,
        message: 'File uploaded successfully!',
      };
    }
  
    async trainForecaster(customerId, region, location, manufacturer, serialNumber, testing) {
      // short delay to avoid timing issues
      await new Promise((resolve) => setTimeout(resolve, 5000));
  
      const params = new URLSearchParams({
        customer_id: customerId,
        location,
        manufacturer,
        region,
        serial_number: serialNumber,
        testing: true,
      });
      const url = `${this.apiTrainUrl}/upload_train?${params.toString()}`;
      const headers = {
        'x-api-key': this.apiKey,
        'Content-Type': 'application/json',
      };
  
      try {
        const response = await fetch(url, { method: 'POST', headers });
        if (!response.ok) {
          throw new Error(`Train error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        return {
          step: 'train',
          success: true,
          data,
          message: 'Training initiated',
        };
      } catch (error) {
        return {
          step: 'train',
          success: false,
          error: error.message,
        };
      }
    }
  }
  