// OnDemand.js
import React, { useState, useEffect } from 'react';
import FormComponent from './FormComponent';
import FormComponentAlt from './FormComponentAlt';
import FormComponentInterpolate from './FormComponentInterpolate';
import OutputComponent from './OutputComponent';

console.log('Logs Proxy API:', process.env.REACT_APP_LOGS_PROXY_API);
console.log('Logs Proxy API Key:', process.env.REACT_APP_LOGS_PROXY_API_KEY);

function OnDemand() {
  // 1) Store the session start time once, on mount
  const [sessionStartTime] = useState(() => Date.now());

  const [activeTab, setActiveTab] = useState('api');
  const [activeSubTab, setActiveSubTab] = useState('quickstart');
  const [response, setResponse] = useState(''); // State to store API response
  const [logEvents, setLogEvents] = useState([]); // State to store raw log events (as an array)
  const [pollIntervalId, setPollIntervalId] = useState(null); // For clearing polling if needed

  // Helper function to fetch logs for a single log group.
  const fetchLogsForGroup = async (logGroup, fixedTimestamp) => {
    const url = `${process.env.REACT_APP_LOGS_PROXY_API}?logGroupName=${encodeURIComponent(
      logGroup
    )}&sinceTimestamp=${fixedTimestamp}`;

    const res = await fetch(url, {
      headers: { 'x-api-key': process.env.REACT_APP_LOGS_PROXY_API_KEY },
    });
    if (!res.ok) {
      throw new Error(`Failed to fetch logs for ${logGroup}`);
    }

    const data = await res.json();
    return data.events || [];
  };

  // Fetch logs from all three log groups, combine, sort, and update persistent state.
  const fetchCombinedLogs = async (fixedTimestamp) => {
    try {
      const groups = [
        process.env.REACT_APP_LOG_GROUP_1,
        process.env.REACT_APP_LOG_GROUP_2,
        process.env.REACT_APP_LOG_GROUP_3,
      ];
      // Fetch logs for each group in parallel.
      const results = await Promise.all(
        groups.map((group) => fetchLogsForGroup(group, fixedTimestamp))
      );
      // Flatten the array of arrays into a single array of events.
      const newEvents = results.flat();
      // Sort new events by timestamp (ascending).
      const sortedNewEvents = newEvents.sort((a, b) => a.timestamp - b.timestamp);

      // Update persistent log events, appending only events that are not already present.
      setLogEvents((prevEvents) => {
        const combined = [...prevEvents];
        sortedNewEvents.forEach((event) => {
          const alreadyExists = prevEvents.some(
            (e) => e.timestamp === event.timestamp && e.message === event.message
          );
          if (!alreadyExists) {
            combined.push(event);
          }
        });
        // Sort combined events before saving.
        combined.sort((a, b) => a.timestamp - b.timestamp);
        return combined;
      });
    } catch (error) {
      console.error('Error fetching combined logs:', error);
      // Optionally, you can append an error event instead of replacing the logs.
    }
  };

  // Polling function to update logs every 5 seconds.
  const startPollingLogs = (fixedTimestamp) => {
    // Clear any existing interval to avoid multiple intervals.
    if (pollIntervalId) clearInterval(pollIntervalId);

    const intervalId = setInterval(() => {
      fetchCombinedLogs(fixedTimestamp);
    }, 5000);

    setPollIntervalId(intervalId);
  };

  /**
   * Called when your form component finishes an API call (upload, train, etc.).
   * We'll show the response in the UI and start fetching logs from sessionStartTime
   * so we catch all logs from the entire session, not just from "now".
   */
  const handleApiResponse = (data) => {
    setResponse(data);
    startPollingLogs(sessionStartTime);
    fetchCombinedLogs(sessionStartTime);
  };

  // Format the persistent log events for display.
  const formattedLogs = logEvents
    .map((event) => {
      const date = new Date(event.timestamp).toLocaleString();
      return `[${date}] ${event.message}`;
    })
    .join('\n');

  /**
   * Render sub-content for the "Getting Started" tab (e.g., Quick Start, Learn More).
   */
  const renderSubContent = () => {
    switch (activeSubTab) {
      case 'quickstart':
        return (
          <div className="code-container">
            <h3>Python Example</h3>
            <p>
              Our API's were designed for easy integration within existing technology stacks.
            </p>
            <p>
              Here is a quick and easy implementation of our historical data ingest API with Python:
            </p>
            <pre>
              <code>
                {`import requests
api_key = 'YOUR_API_KEY'
base_url = 'https://api.asoba.co/v1/upload'
endpoint = 'historical'
headers = {
  'Authorization': f'Bearer {api_key}',
  'Content-Type': 'text/csv'
}
payload = {
  'location': 'Cape Town',
  'filename': '/path/to/csv',
  'customer_id': '280001'
}
response = requests.post(f'{base_url}{endpoint}', headers=headers, json=payload)
if response.status_code == 200:
  print('Success:', response.json())
else:
  print('Error:', response.status_code, response.text)
`}
              </code>
            </pre>
          </div>
        );
      case 'learnmore':
        return <p>This is the Learn More content.</p>;
      case 'utilityinverter':
        return <p>This is the Getting Utility/Inverter Data content.</p>;
      case 'apidocumentation':
        return <p>This is the API Documentation content.</p>;
      default:
        return null;
    }
  };

  /**
   * Render main content based on the active tab.
   */
  const renderContent = () => {
    switch (activeTab) {
      case 'interpolate':
        return (
          <div className="container">
            <div className="form-container">
              <h2>Input</h2>
              <FormComponentInterpolate onResponse={handleApiResponse} />
            </div>
            <div className="output-container">
              <h2>Output</h2>
              <OutputComponent response={response} logs={formattedLogs} />
            </div>
          </div>
        );
      case 'api':
        return (
          <div className="container">
            <div className="form-container">
              <h2>Input</h2>
              <FormComponent onResponse={handleApiResponse} />
            </div>
            <div className="output-container">
              <h2>Output</h2>
              <OutputComponent response={response} logs={formattedLogs} />
            </div>
          </div>
        );
      case 'api2':
        return (
          <div className="container">
            <div className="form-container">
              <h2>Input</h2>
              <FormComponentAlt />
            </div>
            <div className="output-container">
              <h2>Output</h2>
              <OutputComponent response={response} logs={formattedLogs} />
            </div>
          </div>
        );
      case 'guide':
        return (
          <div className="getting-started">
            <div className="left-tabs">
              <ul>
                <li
                  onClick={() => setActiveSubTab('quickstart')}
                  className={activeSubTab === 'quickstart' ? 'active' : ''}
                >
                  Quick Start
                </li>
                <li
                  onClick={() => setActiveSubTab('learnmore')}
                  className={activeSubTab === 'learnmore' ? 'active' : ''}
                >
                  Learn More
                </li>
                <li
                  onClick={() => setActiveSubTab('utilityinverter')}
                  className={activeSubTab === 'utilityinverter' ? 'active' : ''}
                >
                  Getting Utility/Inverter Data
                </li>
              </ul>
            </div>
            <div className="content-area">{renderSubContent()}</div>
          </div>
        );
      default:
        return null;
    }
  };

  // Optional: Clear polling on unmount
  useEffect(() => {
    return () => {
      if (pollIntervalId) clearInterval(pollIntervalId);
    };
  }, [pollIntervalId]);

  return (
    <div className="on-demand">
      <div className="tabs">
        {/* Example tabs */}
        <button
          className={activeTab === 'api' ? 'active' : ''}
          onClick={() => setActiveTab('api')}
        >
          Train Model
        </button>
        {/* <button
          className={activeTab === 'guide' ? 'active' : ''}
          onClick={() => setActiveTab('guide')}
        >
          Getting Started
        </button> */}
      </div>
      {renderContent()}
    </div>
  );
}

export default OnDemand;
