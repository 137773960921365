import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

function FormComponentAlt() {
  const onDrop = useCallback((acceptedFiles) => {
    // Handle the file upload logic here
    console.log(acceptedFiles);
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="form-container">

      <form>
      <label htmlFor="apiKey">API Key:</label>
      <input type="text" id="apiKey" name="apiKey" />
        
        <label htmlFor="customerId">Customer ID:</label>
        <input type="text" id="customerId" name="customerId" />

        <label htmlFor="customerType">Customer Type:</label>
        <select name="languages" id="customerType">
        <option value="consumer">Consumer</option>
        <option value="prosumer">Prosumer</option>

      </select>

        <label htmlFor="uploadFile">Upload File:</label>
        <div {...getRootProps()} className={`dropzone ${isDragActive ? 'active' : ''}`}>
          <input {...getInputProps()} id="uploadFile" name="uploadFile" />
          {isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>Drag 'n' drop some files here, or click to select files</p>
          )}
        </div>

        <button className="submit-button" type="submit">Submit</button>
      </form>
    </div>
  );
}

export default FormComponentAlt;
