// FormComponent.js
import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import OnaUploader from '../services/OnaUploader';

function FormComponent({ onResponse }) {
  const [apiKey, setApiKey] = useState('');
  const [file, setFile] = useState(null);
  const [customerId, setCustomerId] = useState('');
  const [oemName, setOemName] = useState('');
  const [region, setRegion] = useState('');
  const [location, setLocation] = useState('');
  const [serialNumber, setSerialNumber] = useState('');

  // Handle file select/drag-drop
  const onDrop = useCallback((acceptedFiles) => {
    setFile(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!file) {
      alert('Please upload a file before submitting');
      return;
    }
    if (!apiKey) {
      alert('Please provide an API key.');
      return;
    }

    // Immediately clear previous responses and show "Loading..." message
    onResponse("Loading...");

    try {
      // Instantiate the OnaUploader with the API key.
      const onaUploader = new OnaUploader(apiKey);

      console.log('Requesting a presigned URL and uploading file...');
      // Get presigned URL and upload the file to S3.
      const presignedUrl = await onaUploader.uploadFileToS3(
        file,
        customerId,
        region,
        location,
        oemName
      );

      // Initiate model training after upload.
      const trainResponse = await onaUploader.trainForecaster(
        customerId,
        region,
        location,
        oemName,
        serialNumber,
        false
      );

      // Return the final response object.
      onResponse({
        message: 'Upload complete!',
        presignedUrl,
        trainResponse,
      });
    } catch (error) {
      console.error('Error in file upload:', error);
      onResponse(error.message || 'Error in file upload');
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={handleSubmit}>
        <label htmlFor="apiKey">API Key:</label>
        <input
          type="text"
          id="apiKey"
          name="apiKey"
          value={apiKey}
          onChange={(e) => setApiKey(e.target.value)}
          placeholder="Enter your API key"
        />

        <label htmlFor="customerId">Customer ID:</label>
        <input
          type="text"
          id="customerId"
          name="customerId"
          value={customerId}
          onChange={(e) => setCustomerId(e.target.value)}
          placeholder="Enter your unique customer ID"
        />

        <label htmlFor="region">Region:</label>
        <input
          type="text"
          id="region"
          name="region"
          value={region}
          onChange={(e) => setRegion(e.target.value)}
          placeholder="e.g., af-south-1"
        />

        <label htmlFor="location">Location:</label>
        <input
          type="text"
          id="location"
          name="location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          placeholder="e.g., CapeTown"
        />

        <label htmlFor="oemName">Device Manufacturer:</label>
        <select
          id="oemName"
          name="oemName"
          value={oemName}
          onChange={(e) => setOemName(e.target.value)}
        >
          <option value="">-- Select Sensor OEM --</option>
          <option value="lux">Lux</option>
          <option value="macrocomm">Macrocomm</option>
          {/* add others as needed */}
        </select>

        <label htmlFor="serialNumber">Serial Number:</label>
        <input
          type="text"
          id="serialNumber"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)}
          placeholder="e.g., 12345"
        />

        <label htmlFor="uploadFile">Upload CSV File:</label>
        <div
          {...getRootProps()}
          className={`dropzone ${isDragActive ? 'active' : ''}`}
        >
          <input {...getInputProps()} id="uploadFile" name="uploadFile" />
          {isDragActive ? (
            <p>Drop the file here...</p>
          ) : (
            <p>Drag 'n' drop a CSV file, or click to select file</p>
          )}
        </div>

        {file && <p>Selected file: {file.name}</p>}

        <button className="submit-button" type="submit">
          Submit
        </button>
      </form>
    </div>
  );
}

export default FormComponent;
