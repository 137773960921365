import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useAuth0 } from '@auth0/auth0-react';

const Layout = ({ logout, children }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { user } = useAuth0();

  // Get user roles from Auth0
  const userRole = user && user['https://asoba.co/roles'];
  const userRoles = userRole ? (Array.isArray(userRole) ? userRole : [userRole]) : [];

  const toggleCollapse = () => {
    setIsCollapsed((prev) => !prev);
  };

  console.log('Layout.js: Rendering...', { isCollapsed, userRoles });

  // Function to check if user has required roles for a route
  const hasAccess = (allowedRoles) => {
    return allowedRoles.some(role => userRoles.includes(role));
  };

  return (
    <div className={`app ${isCollapsed ? 'collapsed' : ''}`}>
      {/* Topbar */}
      <div className="topbar">
        <div className="navbar-logo-container">
          <img src="/primarylogo.png" alt="Logo" />
          <span>Ona On-Demand</span>
        </div>
      </div>

      {/* Sidebar Navigation */}
      <nav className={`navbar ${isCollapsed ? 'collapsed' : ''}`}>
        <ul>
          <li className="collapse-icon">
            <button onClick={toggleCollapse} className="collapse-button">
              {isCollapsed ? <FaChevronRight /> : <FaChevronLeft />}
            </button>
          </li>
          
          {/* Dashboard - accessible to all authenticated users */}
          <li>
            <NavLink to="/dashboard" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Dashboard
            </NavLink>
          </li>

          {/* OnDemand - accessible to ondemand-alpha and admin */}
          {hasAccess(['admin']) && (
            <li>
              <NavLink to="/on-demand" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                On-Demand
              </NavLink>
            </li>
          )}

          {/* Forecasting - accessible to demandforecast-beta and admin */}
          {hasAccess(['admin']) && (
            <li>
              <NavLink to="/forecasting" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                Forecasting
              </NavLink>
            </li>
          )}

          {/* Chatbot - accessible to basic and ondemand-alpha users (basic users will be redirected to checkout) */}
          {hasAccess(['basic', 'ondemand-alpha', 'admin']) && (
            <li>
              <NavLink to="/chatbot" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
                Policy Analyst
              </NavLink>
            </li>
          )}

          {/* Settings - accessible to all authenticated users */}
          <li>
            <NavLink to="/settings" className={({ isActive }) => (isActive ? 'activeNav' : '')}>
              Settings
            </NavLink>
          </li>

          <li className="logout-item">
            <button
              onClick={(e) => {
                e.preventDefault();
                console.log('Triggering logout...');
                logout();
              }}
              className="nav-button"
              style={{
                background: 'none',
                border: 'none',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left',
                display: 'block',
                padding: '0.5rem 1rem',
                color: '#455BF1',
                textDecoration: 'none',
                fontSize: '1rem',
                fontFamily: 'inherit'
              }}
            >
              Logout
            </button>
          </li>
        </ul>
      </nav>

      {/* Main Content */}
      <div className="main-content">{children}</div>
    </div>
  );
};

export default Layout;
